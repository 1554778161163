<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <header class="header-introduce">
      <b-link
        class="brand-logo"
        :to="{path:'/'}"
      >
        <brand-logo />
      </b-link>
      <div
        v-if="logged"
        class="btn-logout cursor-pointer"
      >
        <b-card-text @click="onLogout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          Logout
        </b-card-text>
      </div>
    </header>

    <div class="misc-inner content-page mb-5">
      <b-img
        fluid
        :src="img"
        class="img"
        alt="img"
      />
      <div class="w-100 text-center mt-2">
        <h2 class="mb-3 title">
          LUNAR NEW YEAR ANNOUNCEMENT
        </h2>
        <p class="text-left">
          Hi there <span style="width: 30px">🙌</span>
        </p>
        <p class="text-left mb-0">
          Ecomdy appreciates your interest in creating an ad account at our platform.
        </p>
        <p class="text-left mb-0">
          We are currently on Lunar New Year holiday from <strong>Jan 18 to Jan 26, 2023 </strong>, therefore the creation of new advertising accounts will take longer.
        </p>
        <p class="text-left">
          Ecomdy appreciates your patience while you waited and ask that you pardon us for the inconvenience.
        </p>
        <p
          v-if="false"
          class="text-left"
        >
          Please leave your email below so that Ecomdy will send you the latest updates.
        </p>
      </div>
      <div
        v-if="false"
        class="mt-3 mail-form"
      >
        <div>
          <validation-observer
            ref="formValidation"
            v-slot="{ invalid }"
          >
            <b-form class="mt-2">
              <b-form-group class="my-2">
                <b-row>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      class="m-0"
                    >
                      <div class="d-flex justify-content-between">
                        <label
                          class="form-label"
                        >
                          Full Name
                        </label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        id="name"
                        name="name"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            v-model.trim="name"
                            class="form-control-merge input-height"
                            name="name"
                            placeholder="Enter full name"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Email -->
                  <b-col
                    cols="12"
                    lg="6"
                    class="mt-2 mt-md-0"
                  >
                    <b-form-group
                      class="m-0"
                      label-for="email"
                    >
                      <div class="d-flex justify-content-between">
                        <label
                          for="email"
                          class="form-label"
                        >
                          {{ $t('common.labelEmail') }}
                        </label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            v-model.trim="email"
                            id="email"
                            name="email"
                            class="input-height"
                            :placeholder="$t('common.placeholderEmail')"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>

              <div class="d-flex justify-content-center">
                <b-button
                  class="font-medium font-14 mr-2"
                  variant="secondary"
                  pill
                  to="/login"
                >
                  Back
                </b-button>
                <btn-loading
                  variant-convert="btn-submit"
                  type="submit"
                  pill
                  :loading="loadingSubmit"
                  :disabled="invalid"
                  @click.prevent="submitForm"
                >
                  Submit
                </btn-loading>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
      <b-button
        class="font-medium font-14 mr-2 mt-2"
        variant="secondary"
        pill
        to="/login"
      >
        Back
      </b-button>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import { createNamespacedHelpers } from 'vuex'
import { MaintainService } from '@/services'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { required, email } from '@validations'
import auth from '@auth'
import { toastification } from '@core/mixins/toast'

const { mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BrandLogo,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BtnLoading,
    BCardText,
    BButton,
  },

  mixins: [toastification],

  data() {
    return {
      img: require('@/assets/images/common/ic-new-year.png'),
      name: '',
      userEmail: '',
      required,
      email,
      loadingSubmit: false,
    }
  },

  computed: {
    logged() {
      const loggedIn = !!auth.getToken()
      return loggedIn
    },
  },

  methods: {
    ...mapActions(['logout']),
    onLogout() {
      this.logout()
    },

    async submitForm() {
      const success = await this.$refs.formValidation.validate()
      this.loadingSubmit = true

      const params = {
        fullName: this.name,
        email: this.email,
        phoneNumber: '',
        proposed: 'waitlistUser',
        country: 'country',
        message: 'From waitList user',
      }

      if (success) {
        try {
          await MaintainService.submitEmail(params)
          this.loadingSubmit = false
          this.toastSuccess('Submit success!')
        } catch {
          this.loadingSubmit = false
          this.toastFailure('Submit fail. Please try again!')
        }
      }
    },

  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
<style scoped lang="scss">
  .img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 250px;
  }

  .title {
    font-size: 34px;
  }

  .des {
    line-height: 24px;
  }

  .content-page {
    margin: 5rem 2rem 2rem;
  }

  .btn-logout {
    position: absolute;
    top: 2.5rem;
    right: 3rem;
  }

  .mail-form{}
</style>
